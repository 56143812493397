import React from 'react';
import styled from 'styled-components';
import { SecondaryHero } from '@latitude/hero';
import { Metadata } from '@latitude/metadata';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Accordion } from '@latitude/accordion';
import { Heading5, Heading6 } from '@latitude/heading';
import { List, ListItem } from '@latitude/list';
import {
  COLOR,
  MARGIN,
  PADDING,
  ALIGN,
  JUSTIFY_CONTENT
} from '@latitude/core/utils/constants';
import { Box } from '@/components/Box/Box';
import Text from '../components/Text/Text';
import Layout from '../components/layout';
import { CardGroup, CardGroupItem } from '../components/CardGroup/CardGroup';
import { BREAKPOINT } from '../utils/constants';

const TermsAndConditionsLibrary = props => {
  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Metadata
          title="Credit Card Terms and Conditions | Latitude Financial"
          description="If you're in market for a new car or another vehicle, a Car Loan from Latitude can help you achieve your goals. Apply today!"
          canonical="https://www.latitudefinancial.com.au/terms-and-conditions-library/"
        />
        <Box
          css={`
            @media (min-width: ${BREAKPOINT.SM}) {
              white-space: nowrap;
            }
          `}
        >
          <SecondaryHero heading="Latitude Credit Card Terms and Conditions Library" />
        </Box>
      </main>
      <Box.Section
        backgroundColor={COLOR.GREY6}
        isResponsive
        margin={`${MARGIN.MAUTO}`}
      >
        <CardGroup>
          <CardGroupItem col={1}>
            <InfoBox title="What is the Latitude Credit Card Terms and Conditions Library?">
              <Text
                css={`
                  // Overrides added below to account for UX request (Ref: CEPW-1205)
                  padding-bottom: 15px;
                `}
              >
                Below you will find current and historic versions of terms and conditions (or conditions of use) for Latitude credit cards.
              </Text>
            </InfoBox>
          </CardGroupItem>
        </CardGroup>
      </Box.Section>
      <AnalyticsLocationProvider location="Our products">
        <Box
          css={`
            // Overrides added below to account for UX request (Ref: CEPW-1205)
            [class^='Box__StyledComponent'] {
              padding-top: 5px;
            }
          `}
        >
          <Box.Section backgroundColor={COLOR.GREY6}>
                <div css={`
                  position:relative;
                  bottom:5px;
                  `}>
                  <p css={`
                    background-color:#D0E9FF;
                    padding:15px 15px;
                    position:relative;
                    top:16px;
                    `}><b>Credit cards currently available from Latitude</b></p>            
                    <div className="py-4 pl-4 pr-4"
                    css={`
                    background-color:#eff7ff`}>
                      <List>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/legals/credit-card-general-terms-and-conditions-13112024.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'general-terms-conditions' }}
                            data-trackid="general-terms-conditions"
                          >
                          Latitude Credit Card General Terms and Conditions 
                          </SectionLink> - applicable to:
                          <ul type="circle">
                            <li>
                              Latitude 28° Global Platinum Mastercard
                            </li>
                            <li>
                              Latitude Gem Visa
                            </li>
                            <li>
                              Latitude GO Mastercard
                              <ul type="circle">
                                <li>
                                  For new customers - effective from 5 March 2025
                                </li>
                                <li>
                                  For existing customers - effective from 24 April 2025
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <ul type="circle">
                            <li>
                            Latitude Low Rate Mastercard - effective from 20 November 2024
                            </li>
                          </ul>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/legals/conditions-of-use/28degrees-au/cou.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: '28d'
                            }}
                            data-trackid="28d"
                          >
                            Latitude 28° Global Platinum Mastercard &mdash; Conditions of Use
                          </SectionLink> – effective from 17 September 2024
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/legals/conditions-of-use/gemvisa-au/cou.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'gemvisa' }}
                            data-trackid="gemvisa"
                          >
                            Latitude Gem Visa &mdash; Conditions of Use
                          </SectionLink> - effective from 15 May 2024 
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/legals/conditions-of-use/gomc-au/cou.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'gomc' }}
                            data-trackid="gomc"
                          >
                            Latitude GO Mastercard &mdash; Conditions of Use
                          </SectionLink> - effective from 15 May 2024
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/legals/conditions-of-use/creditline-afs/cou.pdf" 
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'apple-creditline' }}
                            data-trackid="apple-credline"
                          >
                            CreditLine - acquired at Apple &mdash; Conditions of Use
                          </SectionLink> - effective from 31 October 2024
                        </SectionListItem>
                      </List>
                    </div>
                  </div>
              <Accordion
              fgColor={COLOR.BLACK}
              titleBgColor={COLOR.BLUE_SKY}
              contentBgColor="#eff7ff"
              items={[
                {
                  id: 'available',
                  title: 'Credit cards no longer available from Latitude',
                  content: (
                    <div className="py-4 pl-4 pr-4">
                      <List>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/legals/conditions-of-use/latitude-infinity/cou.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'infinity' }}
                            data-trackid="infinity"
                          >
                            Latitude Infinity Rewards Visa &mdash; Conditions of Use
                          </SectionLink> - effective from  October 2021
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/legals/conditions-of-use/latitude-mc/cou.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'latitude-mc' }}
                            data-trackid="latitude-mc"
                          >
                            Latitude Mastercard &mdash; Conditions of Use
                          </SectionLink> - effective from October 2021
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/legals/conditions-of-use/eco/cou.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'eco'
                            }}
                            data-trackid="eco"
                          >
                            Latitude Eco Mastercard &mdash; Conditions of Use
                          </SectionLink> - effective from October 2018
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/legals/conditions-of-use/creditline-au/cou.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'creditline' }}
                            data-trackid="creditline"
                          >
                            CreditLine &mdash; Conditions of Use
                          </SectionLink> - effective from October 2024
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/legals/conditions-of-use/buyersedge/cou.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'buyersedge' }}
                            data-trackid="buyersedge"
                          >
                            Buyer’s Edge &mdash; Conditions of Use
                          </SectionLink> - effective from October 2024
                        </SectionListItem>
                        <SectionLastListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/legals/conditions-of-use/carecredit/cou.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'carecredit' }}
                            data-trackid="carecredit"
                          >
                            CareCredit &mdash; Conditions of Use
                          </SectionLink> - effective from October 2024
                        </SectionLastListItem>
                      </List>
                    </div>
                  )
                },
                {
                  id: 'archieved-credit-card-terms-conditions',
                  title: 'Archived credit card terms and conditions',
                  content: (
                    <div className="py-4 pl-4 pr-4">
                         <List>
                            <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/legals/conditions-of-use/lowrate/cou.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'lowrate' }}
                            data-trackid="lowrate"
                          >
                            Latitude Low Rate Mastercard &mdash; Conditions of Use
                          </SectionLink> - effective from September 2021
                        </SectionListItem>
                        </List>
                    </div>
                  )
                }
              ]}
            />
          </Box.Section>
        </Box>
      </AnalyticsLocationProvider>
    </Layout>
  );
};

const SectionHeading = styled.h4`
  // Overrides added below to account for UX request (Ref: CEPW-1205)
  margin: 0 0 20px;
  color: ${COLOR.BLACK};
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
`;

const SectionListItem = styled.li`
  // Overrides added below to account for UX request (Ref: CEPW-1205)
  font-weight: 500;
  padding-bottom: 20px;
`;

const SectionLastListItem = styled.li`
  // Overrides added below to account for UX request (Ref: CEPW-1205)
  font-weight: 500;
`;

const SectionLink = styled.a`
  // Overrides added below to account for UX request (Ref: CEPW-1205)
  font-weight: 300;
`;

const InfoBox = ({ title, children }) => (
  <Box
    backgroundColor={COLOR.WHITE}
    padding={`${PADDING.P32}`}
    max-width="1100px"
    height="100%"
    align={ALIGN.LEFT}
    justifyContent={JUSTIFY_CONTENT.LEFT}
  >
    <Title color={COLOR.BLACK} align={ALIGN.LEFT}>
      {title}
    </Title>
    {children}
  </Box>
);

const Title = styled(Heading5)`
  font-size: 24px;
  margin-bottom: ${MARGIN.M24};
`;

export default TermsAndConditionsLibrary;
